<template>

  <div class="search">
    <div class="container-fluid">
      <keyword-search :initialValue="initialValue" @searchWordChanged="searchWordChanged"/>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'KeywordFilter',
    components: {
      KeywordSearch: () => import('@/components/controls/KeywordSearch'),
    },
    props: {
      initialValue: {
        type: String,
        default: ""
      }
    },
    methods: {
      searchWordChanged(searchWord){
        this.$emit('searchWordChanged',searchWord);
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .search {
    width: 100vw;
    position: absolute;
    top: $title-bar-size;
    height: auto;
    padding: 15px 0;
    background: $white-color;
    z-index: inherit;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

</style>